
import {computed, defineComponent, onMounted, ref} from 'vue';
import ApiService from '@/core/services/ApiService';
import {setCurrentPageBreadcrumbs} from '@/core/helpers/breadcrumb';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {useRoute} from 'vue-router';

export default defineComponent({
  name: 'customer-Add-Edit',
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const route = useRoute();
    const loading = ref<boolean>(false);
    const isEdit = route.params.id;
    const parentId = route.query?.parentId;

    const formData = ref({
      name: '',
      emailFrom: '',
      emailFromName: '',
      emailSignature: '',
      phone: '',
      websiteUrl: '',
      streampacUrl: '',
      logoUrl: '',
      houseNumber: '',
      street: '',
      city: '',
      zipcode: '',
      country: '',
      emailFriendlySetting: '',
      countryWhiteArray: '',
      selectedChildCustomer: '',
      isChild: false,
      parentId: 0
    });

    const isCustomerChild = computed(() => {
      return formData.value.parentId !== 0;
    });

    const emailSettingsData = ref([]);
    const countryLisData = ref([]);
    const childrenList = ref([]);

    const rules = ref({
      name: [
        {
          required: true,
          message: 'Customer name is required',
          trigger: 'change'
        }
      ],
      emailFrom: [
        {
          required: true,
          message: 'Customer email is required',
          trigger: 'change'
        }
      ]
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(valid => {
        if (valid) {
          loading.value = true;

          const data: any = formData.value;
          Object.keys(data).forEach(key => {
            if (data[key] === null || data[key] === '') {
              delete data[key];
            }
          });

          let serviceType;
          if (isEdit) {
            serviceType = ApiService.put(`Customer/${route.params?.id}`, data);
          } else {
            delete data['id'];

            data.parentId = parentId;

            serviceType = ApiService.post('Customer', data);
          }

          serviceType
              .then(({data}) => {
                // console.log('create customer response', data);
                loading.value = false;
                Swal.fire({
                  timer: 1500,
                  text: 'Form has been successfully submitted!',
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok, got it!',
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  }
                }).then(() => {
                  if (isEdit) {
                    formData.value = {...formData.value, ...data};
                  } else {
                    (
                        document.getElementById('formRef') as HTMLFormElement
                    ).reset();
                  }
                  saveCountryWhiteList();
                });
              })
              .catch(({response}) => {
                console.log('create customer error', response);
                Swal.fire({
                  text: 'Sorry, looks like there are some errors detected, please try again.',
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok, got it!',
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  }
                });
              });
        } else {
          Swal.fire({
            text: 'Sorry, looks like there are some errors detected, please try again.',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn btn-primary'
            }
          });
          return false;
        }
      });
    };

    const getChildrenList = () => {
      ApiService.get(`Customer/GetChildren/${route.params.id}`)
          .then(({data}) => {
            console.log(data);

            childrenList.value = data;
          })
          .catch(({response}) => {
            console.log('error', response);
          });
    };

    const getCustomerDetails = () => {
      ApiService.get(`Customer/${route.params.id}`)
          .then(({data}) => {
            formData.value = {...formData.value, ...data};
            formData.value.isChild = data.parentId ? true : false;

            getChildrenList();
          })
          .catch(({response}) => {
            console.log('error', response);
          });
    };

    const getEmailFriendlySettingValues = () => {
      ApiService.get(`Customer/GetEmailFriendlySettingValues`)
          .then(({data}) => {
            emailSettingsData.value = data;
          })
          .catch(({response}) => {
            console.log('error', response);
          });
    };

    const getCountryList = () => {
      if (!isEdit) return;

      ApiService.get(`IP2LocationDb/getCountryList`)
          .then(({data}) => {
            countryLisData.value = data;

            getCountryWhiteList();
          })
          .catch(({response}) => {
            console.log('error', response);
          });
    };

    const getCountryWhiteList = () => {
      if (!isEdit) return;

      ApiService.get(`CustomerCountryWhiteList/${route.params.id}`)
          .then(({data}) => {
            formData.value.countryWhiteArray = data;
          })
          .catch(({response}) => {
            console.log('error', response);
          });
    };

    const saveCountryWhiteList = () => {
      if (route.params?.id === null || route.params?.id === undefined) {
        return
      }

      const data: any = formData.value.countryWhiteArray;

      ApiService.post(`CustomerCountryWhiteList/${route.params?.id}`, data)
          .then(({data}) => {
            // countryWhiteList.value = data;
          })
          .catch(({response}) => {
            console.log('error', response);
          });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Customer`, [
        'Customers'
      ]);
      if (isEdit) {
        getCustomerDetails();
      }
      getEmailFriendlySettingValues();
      getCountryList();
    });

    return {
      isCustomerChild,
      rules,
      isEdit,
      parentId,
      submit,
      formRef,
      loading,
      formData,
      emailSettingsData,
      countryLisData,
      childrenList
    };
  }
});
